import 'App.css';

import React, {useState, createContext, useEffect, lazy, Suspense} from 'react';
import {
    Routes,
    Route,
    HashRouter,
    Navigate
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import toast, { Toaster } from 'react-hot-toast';
import { getUser } from './controllers/UserController';
import { getNextMaintenanceDate } from './controllers/MaintenanceDateController';

const MainPage = lazy(() => import('./components/MainPage/MainPage'));
const LoginPage = lazy(() => import('./components/LoginPage'));
const ResetLearning = lazy(() => import('./components/ResetLearning/ResetLearning'));
const LessonPage = lazy(() => import('./components/LessonPage'));
const LogoutPage = lazy(() => import('./components/LogoutPage'));
const ReviewListPage = lazy(() => import('./components/ReviewListPage/ReviewListPage'));
const DesktopMainPage = lazy(() => import('./components/DesktopMainPage/DesktopMainPage'));
const DesktopLearning = lazy(() => import('./components/DesktopLearning/DesktopLearning'));
const DesktopBooks = lazy(() => import('./components/DesktopBooks/DesktopBooks'));
const Learning = lazy(() => import('./components/Learning/Learning'));
const Books = lazy(() => import('./components/Books/Books'));
const LearningRateInfo = lazy(() => import('./components/LearningRateInfo/LearningRateInfo'));
const DesktopDashboard = lazy(() => import('./components/DesktopAdminPage/Dashboard/DesktopDashboard'));
const EditGrammars = lazy(() => import('./components/DesktopAdminPage/EditGrammars/EditGrammars'));
const About = lazy(() => import('./components/About/About'));
const AboutWithTopBar = lazy(() => import('./components/About/AboutWithTopBar'));
const DesktopReviewListPage = lazy(() => import('./components/DesktopReviewListPage/DesktopReviewListPage'));
const Contact = lazy(() => import('./components/Contact/Contact'));
const DesktopContact = lazy(() => import('./components/DesktopContact/DesktopContact'));
const UserMessages = lazy(() => import('./components/DesktopAdminPage/UserMessages/UserMessages'));
const EditLemmas = lazy(() => import('./components/DesktopAdminPage/EditLemmas/EditLemmas'));
const DesktopVerseStatus = lazy(() => import('./components/DesktopVerseStatus/DesktopVerseStatus'));
const ExtraCharts = lazy(() => import('./components/DesktopAdminPage/UsageHeatMap/ExtraCharts'));
const Dashboard = lazy(() => import('./components/AdminPage/Dashboard'));
const AdminPage = lazy(() => import('./components/AdminPage/AdminPage'));
const DesktopAdminPage = lazy(() => import('./components/DesktopAdminPage/DesktopAdminPage'));
const LearningSettings = lazy(() => import('./components/Learning/LearningSettings/LearningSettings'));
const Maintenance = lazy(() => import('./components/DesktopAdminPage/Maintenance/Maintenance'));
const Analysis = lazy(() => import('./components/Analysis/Analysis'));

export const ThemeContext = createContext(null);

export const darkColor = '#171717';
export const lightColor = '#d9d9d9';

function App() {
    const [useDarkMode, setUseDarkMode] = useState(true);
    const [screenIsBig, setScreenIsBig] = useState(false);
    const [hasMaintLock, setHasMaintLock] = useState(false);

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [user, setUser] = useState(undefined);

    useEffect(() => { 
        if (isAuthenticated) {
            fetchDataOnAuthentication();
        }
    }, [isAuthenticated])

    const fetchDataOnAuthentication = async () => {
        const accessToken = await getAccessTokenSilently();
        // This will create a new db object for the user if one does not already exist.
        const newUser = await getUser(accessToken);
        setUser(newUser);

        // Check if we should notify the user of impending maintenance
        const nextMaintenanceDate = await getNextMaintenanceDate(accessToken);
        if (nextMaintenanceDate) {
            const date = new Date(nextMaintenanceDate.date + 'Z');
            // If the date is in the future, notify the user
            if (date.getTime() > Date.now()) {
                const timeDiff = Math.abs(date.getTime() - Date.now());
                const hoursDiff = Math.ceil(timeDiff / (1000 * 60 * 60));
                const minutesDiff = Math.ceil(timeDiff / (1000 * 60));
                let toastMessage = `FYI: Upcoming website maintenance at ${date.toLocaleString()}`;
                if (hoursDiff <= 1) {
                    if (minutesDiff <= 1) {
                        toastMessage = `FYI: Upcoming website maintenance in less than a minute`;
                    } else {
                        toastMessage = `FYI: Upcoming website maintenance in ${minutesDiff} minutes`;
                    }
                } else if (hoursDiff < 6) {
                    toastMessage = `FYI: Upcoming website maintenance in ${hoursDiff} hours`;
                }
                toast(toastMessage, {
                    icon: '🛠️',
                });
            }
            // If the date is in the past, notify the user and lock if needed
            else {
                if (nextMaintenanceDate.isLocking) {
                    setHasMaintLock(true);
                }
                toast(`FYI: Website maintenance is underway`, {
                    icon: '🛠️',
                });
            }
        }
    }

    const mainPageUI = screenIsBig ? <DesktopMainPage user={user}/> : <MainPage/>;
    const learningUI = screenIsBig ? <DesktopLearning/> : <Learning user={user}/>;
    const booksUI = screenIsBig ? <DesktopBooks/> : <Books user={user}/>;
    const aboutUI = screenIsBig ? <About/> : <AboutWithTopBar user={user}/>;
    const contactUI = screenIsBig ? <DesktopContact/> : <Contact/>;
    const reviewListPageUI = screenIsBig ? <DesktopReviewListPage/> : <ReviewListPage/>;
    const adminUI = screenIsBig ? <DesktopAdminPage/> : <AdminPage user={user}/>;
    const dashboardUI = screenIsBig ? <DesktopDashboard/> : <Dashboard/>;

    useEffect(() => {
        const handleResize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            // Try to guess at the screen size
            const screenWidth = window.innerWidth;
            const guessedDpi = window.devicePixelRatio * 96; 
            const screenWidthInInches = screenWidth / guessedDpi;
            const screenIsBig = screenWidthInInches >= 6;
            setScreenIsBig(screenIsBig);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (hasMaintLock && user.isAdmin === false) {
        return (
            <div className='maintenance-page'>
                <h1>Website Maintenance 🛠️</h1>
                <p>Sorry, the website is currently down for maintenance. This shouldn't take long. Please check back soon.</p>
            </div>
        );
    }

    return (
        <div>
            <Toaster position='top-center'/><HashRouter>
            <ThemeContext.Provider value={{ useDarkMode: useDarkMode, setUseDarkMode: setUseDarkMode }}>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path='/login' element={<LoginPage/>}/>
                        <Route path='/logout' element={<LogoutPage/>}/>
                        <Route path='/analysis' element={<Analysis/>}/>
                        <Route path='*' element={mainPageUI}>
                            <Route path='learning' element={learningUI}/>
                            <Route path='filter-by-book' element={booksUI}/>
                            <Route path='admin' element={adminUI}>
                                <Route path='dashboard' element={dashboardUI}/>
                                <Route path='edit-grammars' element={<EditGrammars/>}/>
                                <Route path='user-messages' element={<UserMessages/>}/>
                                <Route path='edit-lemmas' element={<EditLemmas/>}/>
                                <Route path='charts' element={<ExtraCharts/>}/>
                                <Route path='maintenance' element={<Maintenance/>}/>
                                <Route path='*' element={<Navigate to='/admin/dashboard'/>}/>
                            </Route>
                            <Route path='review-list' element={reviewListPageUI}/>
                            <Route path='reset-learning' element={<ResetLearning/>}/>
                            <Route path='learning-rate-info' element={<LearningRateInfo/>}/>
                            <Route path='about' element={aboutUI}/>
                            <Route path='contact' element={contactUI}/>
                            <Route path='learn' element={<LessonPage/>}/>
                            <Route path='review-status/:level' element={<DesktopVerseStatus/>}/>
                            <Route path='settings' element={<LearningSettings/>}/>
                            <Route path='*' element={<Navigate to='/learning'/>}/>
                        </Route>
                    </Routes>
                </Suspense>
            </ThemeContext.Provider>
            </HashRouter>
        </div>
    );
}

export default App;